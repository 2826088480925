import {message} from 'antd'
import {
    checkData,
    checkData0,
    checkDataArray,
    checkDataBool,
    checkDataFunction,
    checkDataObject,
    connectClass,
    isDev,
    isNotNull,
    isNotNullOrEmpty,
    requireDefault, ternaryObject,
} from './functions'
import {Navigate, Route, Routes} from 'react-router-dom'
import {useEffect, useState} from "react";
import axios from "axios";
import {divide as npDivide, minus as npMinus, plus as npPlus, times as npTimes} from 'number-precision'
import {Radio} from 'antd';

const initData = {},
    {location, open: windowOpen} = window,
    token = sessionStorage.getItem('token'),
    windowReplace = path => location.href = path,
    url = isDev ? 'https://test-api.petgpt.ai/v1/' : 'https://api.petgpt.ai/v1/'
export default connectClass(props => {
    const pathName = location.pathname.slice(1),
        pN = pathName.split('/'),
        pN0 = pN[0],
        [data, stateData] = useState({...initData}),
        setData = (d, clean) => stateData(data => ({...(clean ? initData : data), ...d})),
        [messageApi, contextHolder] = message.useMessage(),
        toast = content => messageApi.open({content}),
        toggleShowLoading = showLoading => setData({showLoading}),
        showLoading = checkDataBool(data, 'showLoading'),
        loading = showLoading &&
            <div className={'positionFixed flexColumnCenter'}
                 style={{
                     top: 0,
                     left: 0,
                     right: 0,
                     bottom: 0,
                     zIndex: 1000,
                     background: 'rgba(0,0,0,.56)',
                 }}>
                <img src={requireDefault('loading')} alt="" style={{width: '5rem'}}/>
            </div>,
        removeToken = () => {
            sessionStorage.removeItem('token')
            location.reload()
        },
        request = (api, data, method) => {
            !showLoading && toggleShowLoading(true)
            return axios(
                {
                    method,
                    url: `${url}${api}`,
                    withCredentials: true,
                    [method ? 'data' : 'params']: data,
                    headers: token
                        ? {'Authorization': `Bearer ${token}`}
                        : {}
                },
            ).then(r => {
                toggleShowLoading()
                const d = checkDataObject(r, 'data'),
                    isSuccess = d.code === '1'
                !isSuccess && toast(d.msg);
                return isSuccess ? checkDataObject(d, 'data') : null
            }).catch(e => {
                toggleShowLoading()
                toast('Unknown error')
                console.error(`------------------------------\n`, e, `------------------------------\n`)
            })
        },
        requestGet = (api, data) => request(api, data),
        requestPost = (api, data) => request(api, data, 'post'),
        pageContent = content => <div className={'flex'} style={{height: '100vh'}}>
            <div className={'padding backgroundWhite height100 flexColumn boxSizingBorderBox'}
                 style={{width: '5rem'}}>
                <img src={requireDefault('textLogo')} alt="" className={"width100"}/>
                <div style={{marginTop: '.6rem'}}
                     className={'childrenSpacingTopBigger overflowYScroll flexGrow1Column'}>
                    {
                        [
                            ['dashboard', 'Dashboard'],
                            ['jobDesk', 'Job Desk', [
                                ['boostcard', 'Boost Card'],
                                ['dropnft', 'Drop NFT'],
                            ]],
                            ['chat', 'Chat', [
                                ['filter', 'Filter'],
                                ['character', 'Character'],
                                ['cattype', 'CatType'],
                            ]],
                            ['devJobDesk', 'Dev Job Desk', [
                                ['nftmanage', 'NFT Manage'],
                                ['setdrop', 'Set Drop']
                            ]],
                        ].map(v => {
                                const key = v[0], children = checkDataArray(v, 2),
                                    childrenKeys = children.map(v => v[0]),
                                    childrenShowKey = `${key}ChildrenShow`,
                                    childrenIncludesPathName = childrenKeys.includes(pN0),
                                    childrenShow = checkData([data, childrenShowKey], childrenIncludesPathName),
                                    childrenIsNotNullOrEmpty = isNotNullOrEmpty(childrenKeys),
                                    isCurrentPath = pN0 === key || childrenKeys.includes(pN0)
                                return <div className={'childrenSpacingTop'}>
                                    <div onClick={() => childrenIsNotNullOrEmpty
                                        ? setData({
                                            [childrenShowKey]: !childrenShow
                                        })
                                        : windowReplace(key)}
                                         className={`flexCenter justifyContentSpaceBetween cursorPointer
                                             ${isCurrentPath ? 'backgroundActiveColor' : (childrenShow ? '' : 'colorGray')} borderRadius padding`}>
                                        <div className={'flexCenter childrenSpacingLeft'}>
                                            <img src={requireDefault(`${v[0]}${isCurrentPath ? 'Active' : ''}`)}
                                                 alt="" style={{width: '.55rem'}}/>
                                            <div>{v[1]}</div>
                                        </div>
                                        <img src={requireDefault(childrenShow ? 'toBottom' : 'toRight')} alt=""
                                             style={{width: '.7rem'}}/>
                                    </div>
                                    {
                                        isNotNullOrEmpty(children) && childrenShow && children.map(v => <div>
                                            <div onClick={() => windowReplace(v[0])} style={{paddingLeft: '.95rem'}}
                                                 className={`${pN0.toLocaleLowerCase() === v[0].toLocaleString() ? 'backgroundGrey' : ''} cursorPointer borderRadius padding`}

                                            >
                                                {v[1]}
                                            </div>
                                        </div>)
                                    }
                                </div>
                            }
                        )
                    }
                </div>
            </div>
            <div
                className={'paddingBigger boxSizingBorderBox flexGrow1 height100 backgroundGrey'}>
                <div className={'childrenSpacingTopBig height100 flexColumn'}>
                    <div className={'flexCenter justifyContentFlexEnd'}>
                        <div className={'flexCenter childrenSpacingLeftBigger'}>
                            <div className={'flexCenter childrenSpacingLeft cursorPointer'}>
                                <img src={requireDefault('language')} alt="" style={{width: '.5rem'}}/>
                                <div>EN</div>
                            </div>
                            {
                                [
                                    ['tip', () => {
                                    }],
                                    ['question', () => {
                                    }],
                                ].map(v => <img onClick={v[1]} src={requireDefault(v[0])} alt=""
                                                style={{width: '.5rem'}} className={'cursorPointer'}/>)
                            }
                            {
                                (() => {
                                    const showLogOut = checkDataBool(data, 'showLogOut')
                                    return <div className={'positionRelative cursorPointer'}
                                                onClick={() => setData({showLogOut: !showLogOut})}>
                                        <div
                                            className={'backgroundWhite borderRadius paddingSmall flexCenter justifyContentSpaceBetween cursorPointer'}
                                            style={{width: '3rem'}}
                                        >
                                            <div className={'flexCenter childrenSpacingLeft'}>
                                                <img src={requireDefault('account')} className={'borderRadius999'}
                                                     alt=""
                                                     style={{width: '.7rem'}}/>
                                                <div className={'colorGray'}>jari</div>
                                            </div>
                                            <img src={requireDefault('toBottomGray')} alt="" style={{width: '.4rem'}}/>
                                        </div>
                                        {showLogOut && <div onClick={removeToken}
                                                            className={'positionAbsolute backgroundWhite  flexCenter padding boxSizingBorderBox borderRadius'}
                                                            style={{top: '120%', left: 0, right: 0}}
                                        >
                                            Log Out
                                        </div>}
                                    </div>
                                })()
                            }
                        </div>
                    </div>
                    <div className={'overflowYScroll paddingTopBig'}>
                        {content}
                    </div>
                </div>
            </div>
        </div>,
        defaultTemp = checkDataObject({}, pN0),
        temp = checkData([data, 'temp'], defaultTemp),
        setTemp = t => setData({temp: t ? {...temp, ...t} : defaultTemp}),
        inputContent = content => <div className={'childrenSpacingTopBig'}>
            <div>{content[0]}</div>
            {
                (() => {
                    const hiddenPasswordKey = content[3],
                        hiddenPassword = hiddenPasswordKey
                            ? checkData([data, hiddenPasswordKey], true)
                            : null
                    return <div
                        className={'paddingBig borderRadius flexCenter justifyContentSpaceBetween backgroundGrey'}>
                        {content[4]
                            ? <textarea disabled={content[6]}
                                        className={'width100'}
                                        value={temp[content[2]]}
                                        placeholder={content[1]}
                                        onChange={e => setTemp({[content[2]]: e.target.value})}
                            />
                            : <input type={hiddenPassword ? 'password' : 'text'} placeholder={content[1]}
                                     disabled={content[6]}
                                     className={'width100'}
                                     value={temp[content[2]]}
                                     onChange={e => setTemp({[content[2]]: e.target.value})}
                                     onKeyUp={e => e.keyCode === 13 && checkDataFunction(content, 5)()}
                            />}
                        {isNotNull(hiddenPassword) &&
                            <img onClick={() => setData({[hiddenPasswordKey]: !hiddenPassword})}
                                 src={requireDefault(hiddenPassword ? 'inputIcons' : 'inputIcons1')}
                                 alt="" style={{width: '.5rem'}} className={'cursorPointer'}/>}
                    </div>

                })()
            }
        </div>,
        sizes = [10, 20, 30],
        size = checkData([data, 'size'], 10),
        pages = (() => {
            const arr = [], total = checkData0(data, 'total')
            for (let i = 0; i < Math.ceil(npDivide(total > 0 ? total : 1, size)); i++) {
                arr.push(i + 1)
            }
            return arr
        })(),
        current = (() => {
            const current = checkData([data, 'current'], 1),
                lastPage = pages[pages.length - 1]
            return current > lastPage ? lastPage : current
        })(),
        userId = checkData([data, 'userId']),
        setCurrent = current => {
            const [page0 = 1] = pages, pageMax = checkData([pages, pages.length - 1], 1)
            setData({current: current < page0 ? page0 : (current > pageMax ? pageMax : current)})
        },
        getUpperCode = () => axios(
            {
                responseType: 'blob',
                url: `${url}sys/syscode`,
            },
        ).then(r => {
            const reader = new FileReader()
            reader.onload = (e) => setData({
                upperCode: e.target.result
            })
            reader.readAsDataURL(r.data)
        }),
        getList = (api, params = {}) => requestGet(`back/${api}`, {current, size, ...params}).then(r => setData({
            total: checkData0(r, 'total'),
            chatFilter: checkDataArray(r, 'records'),
        })),
        getChatFilter = () => getList('getChatFilter'),
        getCharacters = () => getList('getCharacters'),
        getCatTypes = () => getList('getCatTypes'),
        getBoostCard = () => getList('getAcceleratorPage', ternaryObject(isNotNullOrEmpty(userId), {userId})),
        getDropNftHistory = () => getList('getDropNftHistory', ternaryObject(isNotNullOrEmpty(userId), {userId})),
        getGameNftItem = () => getList('getGameNftItem', ternaryObject(isNotNullOrEmpty(userId), {typeId: userId})),
        handleToast = (r, func = () => {
        }, falseFunc = () => {
        }) => {
            if (r) {
                toast('Success')
                func()
            } else {
                falseFunc()
            }
        },
        paging = <div className={'flexCenter justifyContentFlexEnd childrenSpacingLeft'}>
            <div
                className={'flex alignItemsCenter overflowXScroll childrenSpacingLeft flexGrow1'}>
                {
                    [
                        ['<', () => setCurrent(current - 1)],
                        ...pages.map(v => [v, () => setCurrent(v), current === v]),
                        ['>', () => setCurrent(current + 1)],
                    ].map(v => <div onClick={v[1]}
                                    className={`${v[2] ? 'backgroundActiveColor borderSmallBlackLine' : 'backGroundBlueGray'}
                                                                     flexCenter colorGray cursorPointer boxSizingBorderBox`}
                                    style={{
                                        borderRadius: '.1rem',
                                        minWidth: '.5rem',
                                        height: '.5rem'
                                    }}
                    >{v[0]}</div>)
                }
            </div>
            {
                (() => {
                    const showSizes = checkDataBool(data, 'showSizes')
                    return <div onClick={() => setData({showSizes: !showSizes})}
                                className={'positionRelative colorGray cursorPointer'}>
                        <div
                            className={'backGroundBlueGray flexCenter boxSizingBorderBox'}
                            style={{
                                borderRadius: '.1rem',
                                minWidth: '.5rem',
                                height: '.5rem'
                            }}
                        >
                            <div
                                className={'flexCenter childrenSpacingLeft paddingLeftRight'}>
                                <div>{size}条/页</div>
                                <img src={requireDefault('toBottomGray')} alt=""
                                     style={{width: '.4rem'}}/>
                            </div>
                        </div>
                        {showSizes &&
                            <div
                                className={'positionAbsolute backGroundBlueGray padding childrenSpacingTop'}
                                style={{
                                    left: 0,
                                    right: 0,
                                    bottom: '150%',
                                    borderRadius: '.1rem',
                                }}>
                                {
                                    sizes.map(v => <div className={'flexCenter'}
                                                        onClick={() => setData({
                                                            size: v,
                                                            current: undefined
                                                        })}
                                    >
                                        {v}条/页
                                    </div>)
                                }
                            </div>
                        }
                    </div>
                })()
            }
        </div>,
        {showDeleteConfirm} = data,
        setShowDeleteConfirm = showDeleteConfirm => {
            !showDeleteConfirm && setTemp()
            setData({showDeleteConfirm})
        },
        deleteConfirm = (deleteApi, func = () => {
        }) => showDeleteConfirm && <div className={'positionFixed flexColumnCenter'}
                                        style={{
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            zIndex: 1000,
                                            background: 'rgba(0,0,0,.56)',
                                        }}>
            <div className={'backgroundWhite paddingBigger borderRadius childrenSpacingTopBigger'}
                 style={{width: '45%'}}
            >
                <div className={'flexCenter justifyContentSpaceBetween'}>
                    <div style={{fontSize: '.6rem'}}>Title Content</div>
                    <img onClick={() => setShowDeleteConfirm()} src={requireDefault('close')} alt=""
                         style={{width: '.5rem'}} className={'cursorPointer'}/>
                </div>
                <div className={'flexColumnCenter childrenSpacingTopBig'}>
                    <img src={requireDefault('warning')} alt="" style={{width: '2rem'}}/>
                    <div className={'biggerFontSize'}>Are you sure you want to delete</div>
                </div>
                <div className={'flexCenter justifyContentFlexEnd childrenSpacingLeftBig'}>
                    <div
                        className={'backgroundBlack flexCenter activeColor borderRadius999 cursorPointer'}
                        style={{padding: '.3rem .7rem .3rem .7rem'}}
                        onClick={() => request(`back/${deleteApi}/${temp.id}`, undefined, 'delete')
                            .then(r => handleToast(r, () => {
                                func()
                                setShowDeleteConfirm()
                            }))}
                    >
                        Confirm
                    </div>
                    <div
                        className={'flexCenter borderRadius999 cursorPointer'}
                        style={{padding: '.24rem .64rem .24rem .64rem', border: 'solid .06rem'}}
                        onClick={() => setShowDeleteConfirm()}
                    >
                        Cancel
                    </div>
                </div>
            </div>
        </div>,
        button = (text = '', func = () => {
        }) => <div onClick={func}
                   style={{padding: '.3rem .7rem .3rem .7rem'}}
                   className={'backgroundBlack flexCenter colorWhite borderRadius999 cursorPointer'}
        >
            {text}
        </div>,
        paths = {
            filter: pageContent(
                (() => {
                    const arrProportion = arr => arr.map((v, i) => [
                            v,
                            [25, 25, 16, 16, 16][i]
                        ]),
                        {showEdit} = data,
                        setShowEdit = showEdit => {
                            !showEdit && setTemp()
                            setData({showEdit})
                        },
                        tempSub = JSON.parse(JSON.stringify(checkDataArray(temp, 'aiChatFilterSubList'))),
                        setTempSub = (tempSub = []) => setTemp({aiChatFilterSubList: tempSub})
                    return <div>
                        <div className={'childrenSpacingTopBigger'}>
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'saf biggerFontSize'}>
                                    Chat Filter
                                </div>
                                {button('+ Add Chat Filter', () => setShowEdit(true))}
                            </div>
                            <div className={'backgroundWhite paddingBigger childrenSpacingTopBig borderRadius'}>
                                <div className={'fontWeightBolder bigFontSize'}>猫咪 Reply 可以添加的变量</div>
                                <div className={'flex alignItemsCenter flexWrap'}>
                                    {
                                        [
                                            '{catType} 对应cat_type表的type_name',
                                            '{catId} 对应猫咪tokenId',
                                            '{scientistId} 对应猫咪科学家tokenId',
                                            '{level} 猫咪等级',
                                            '{mintTime} mint时间',
                                            '{eyes} 眼睛',
                                            '{ears} 耳朵',
                                            '{tail} 尾巴',
                                            '{fur} 皮毛',
                                            '{display} 显示',
                                            '{mouth} 嘴巴',
                                            '{nose} 鼻子',
                                            '{whiskers} 胡须',
                                        ].map(v => <div
                                            className={'paddingSmall backGroundBlueGray borderRadius999 marginRight marginTop'}>
                                            {v}
                                        </div>)
                                    }
                                </div>
                            </div>
                            <div className={'backgroundWhite paddingBigger childrenSpacingTopBigger borderRadius'}>
                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div className={'fontWeightBolder bigFontSize'}>Filter view</div>
                                </div>
                                <div className={'childrenSpacingTopBigger'}>
                                    {
                                        [
                                            <div
                                                className={'flexCenter justifyContentSpaceBetween padding backGroundBlueGray'}>
                                                {
                                                    (() => {
                                                        const arr = arrProportion(['Regex', 'Reply', 'Score', 'Moderations', 'Action'])
                                                        return arr.map(
                                                            (v, i) => <div style={{width: `${v[1]}%`}}
                                                                           className={`flex colorGray  
                                                ${i === arr.length - 1 ? 'justifyContentFlexEnd' : ''}`}>{v[0]}</div>
                                                        )
                                                    })()
                                                }
                                            </div>,
                                            ...checkDataArray(data, 'chatFilter').map(v => <div
                                                    className={'flexCenter justifyContentSpaceBetween paddingLeftRight'}>
                                                    {
                                                        (() => {
                                                            const arr = arrProportion([
                                                                v.regex,
                                                                <pre className={'textOverflowEllipsis preWrap'}
                                                                     style={{maxHeight: '100%', lineHeight: '1.2'}}
                                                                >{v.reply}</pre>,
                                                                v.score,
                                                                v.moderations,
                                                                <div className={'flexCenter childrenSpacingLeftBigger'}>
                                                                    {
                                                                        [
                                                                            ['edit', () => {
                                                                                setTemp(v)
                                                                                setShowEdit(true)
                                                                            }],
                                                                            ['delete', () => {
                                                                                setTemp(v)
                                                                                setShowDeleteConfirm(true)
                                                                            }],
                                                                        ].map(v => <img src={requireDefault(v[0])}
                                                                                        alt=""
                                                                                        className={'cursorPointer'}
                                                                                        onClick={v[1]}
                                                                                        style={{width: '.5rem'}}/>)
                                                                    }
                                                                </div>,
                                                            ])
                                                            return arr.map(
                                                                (v, i) => <div
                                                                    style={{width: `${v[1]}%`, height: '.74rem'}}
                                                                    className={`flex alignItemsCenter textOverflowEllipsis 
                                                ${i === arr.length - 1 ? 'justifyContentFlexEnd' : ''}`}>{v[0]}</div>
                                                            )
                                                        })()
                                                    }
                                                </div>
                                            ),
                                            paging
                                        ]
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            showEdit &&
                            <div className={'positionFixed flexColumnCenter'}
                                 style={{
                                     top: 0,
                                     left: 0,
                                     right: 0,
                                     bottom: 0,
                                     zIndex: 1000,
                                     background: 'rgba(0,0,0,.56)',
                                 }}>
                                <div
                                    className={'backgroundWhite paddingBigger borderRadius childrenSpacingTopBigger overflowYScroll'}
                                    style={{width: '45%', maxHeight: '100vh'}}
                                >
                                    <div className={'flexCenter justifyContentSpaceBetween'}>
                                        <div style={{fontSize: '.6rem'}}>Add Chat Filter</div>
                                        <img onClick={() => setShowEdit()} src={requireDefault('close')} alt=""
                                             style={{width: '.5rem'}} className={'cursorPointer'}/>
                                    </div>
                                    <div className={'childrenSpacingTopBigger'}>
                                        {
                                            [
                                                ['Regex', '^生日$', 'regex'],
                                                ['Reply', '本喵是这个时间{minttime}蹦出来的', 'reply', undefined, true],
                                                ['Score', '10', 'score'],
                                                ['Moderations(Moderations have priority over reply and sub chat filter)', '', 'moderations'],
                                            ].map(v => inputContent(v))
                                        }
                                        <div className={'flex'}>
                                            <div
                                                className={'backgroundBlack flexCenter activeColor borderRadius999 cursorPointer'}
                                                style={{padding: '.3rem .7rem .3rem .7rem'}}
                                                onClick={() => setTempSub([...tempSub, {}])}
                                            >
                                                + Add Sub Chat Filter
                                            </div>
                                        </div>
                                        {
                                            (() => {
                                                const arrProportionSub = arr => arr.map((v, i) => [
                                                    v,
                                                    [85, 10][i]
                                                ])
                                                return <div className={'childrenSpacingTop'}>
                                                    <div
                                                        className={'flexCenter justifyContentSpaceBetween padding backgroundGrey'}>
                                                        {
                                                            (() => {
                                                                const arr = arrProportionSub(['Reply', 'Operate'])
                                                                return arr.map(
                                                                    (v, i) => <div
                                                                        style={{width: `${v[1]}%`}}
                                                                        className={`flex colorGray 
                                                ${i === arr.length - 1 ? 'justifyContentFlexEnd' : ''}`}>{v[0]}</div>
                                                                )
                                                            })()
                                                        }
                                                    </div>
                                                    {
                                                        tempSub.map((v, i) => !v.isDelete &&
                                                            <div style={{paddingBottom: '.2rem'}}
                                                                 className={'flexCenter justifyContentSpaceBetween paddingLeftRight borderBottomSmallGrayLine'}>
                                                                {
                                                                    (() => {
                                                                        const arr = arrProportionSub([
                                                                            <textarea
                                                                                value={checkData([v, 'newReply'], v.reply)}
                                                                                onChange={e => {
                                                                                    tempSub[i].newReply = e.target.value
                                                                                    setTempSub(tempSub)
                                                                                }}
                                                                                className={'textOverflowEllipsis preWrap width100'}
                                                                                style={{
                                                                                    maxHeight: '100%',
                                                                                    lineHeight: '1.2'
                                                                                }}
                                                                            />,
                                                                            <div
                                                                                className={'flexCenter childrenSpacingLeftBigger'}>
                                                                                {
                                                                                    [
                                                                                        ['delete', () => {
                                                                                            tempSub[i].isDelete = true
                                                                                            setTempSub(tempSub)
                                                                                        }],
                                                                                    ].map(v => <img
                                                                                        className={'cursorPointer'}
                                                                                        onClick={v[1]}
                                                                                        src={requireDefault(v[0])}
                                                                                        alt=""
                                                                                        style={{width: '.5rem'}}/>)
                                                                                }
                                                                            </div>,
                                                                        ])
                                                                        return arr.map(
                                                                            (v, i) => {
                                                                                const style = {
                                                                                        width: `${v[1]}%`,
                                                                                        height: '.74rem'
                                                                                    },
                                                                                    className = `flex alignItemsCenter textOverflowEllipsis 
                                                ${i === arr.length - 1 ? 'justifyContentFlexEnd' : ''}`
                                                                                return <div
                                                                                    style={style}
                                                                                    className={className}>{v[0]}</div>
                                                                            }
                                                                        )
                                                                    })()
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            })()
                                        }
                                        <div
                                            className={'flex alignItemsCenter childrenSpacingLeftBig'}>
                                            <div
                                                className={'backgroundBlack flexCenter activeColor borderRadius999 cursorPointer'}
                                                style={{padding: '.3rem .7rem .3rem .7rem'}}
                                                onClick={() => requestPost(
                                                    'back/insertOrUpdateChatFilter',
                                                    temp
                                                ).then(r => (r || r === 0)
                                                    ? Promise.all(tempSub.map(
                                                        async v => await (
                                                            v.isDelete
                                                                ? (
                                                                    isNotNull(v.id)
                                                                        ? request(
                                                                            `back/deleteChatFilterSub/${v.id}`,
                                                                            undefined, 'delete'
                                                                        )
                                                                        : (new Promise(resolve => resolve(true)))
                                                                )
                                                                : (() => {
                                                                    const reply = v.newReply
                                                                    return isNotNull(reply) && reply !== v.reply
                                                                        ? requestPost(
                                                                            'back/insertOrUpdateChatFilterSub',
                                                                            {
                                                                                ...v,
                                                                                reply,
                                                                                parentId: checkData([temp, 'id'], r)
                                                                            }
                                                                        )
                                                                        : (new Promise(resolve => resolve(true)))
                                                                })()
                                                        )
                                                    ))
                                                    : false
                                                ).then(r => handleToast(r, () => {
                                                    getChatFilter()
                                                    setShowEdit()
                                                }))}
                                            >
                                                Save
                                            </div>
                                            <div
                                                className={'flexCenter borderRadius999 cursorPointer'}
                                                style={{
                                                    padding: '.24rem .64rem .24rem .64rem',
                                                    border: 'solid .06rem'
                                                }}
                                                onClick={() => setShowEdit()}
                                            >
                                                Cancel
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {deleteConfirm('deleteChatFilter', getChatFilter)}
                    </div>
                })()
            ),
            'login': <div className={'flex'} style={{height: '100vh'}}>
                <img src={requireDefault('imgBg')} alt="" style={{width: '50%'}}/>
                <div style={{width: '50%'}} className={'boxSizingBorderBox paddingPhone'}>
                    <div
                        className={'height100 flexColumn justifyContentSpaceBetween childrenSpacingTopBig overflowYScroll'}>
                        <div className={'childrenSpacingTopBig'}>
                            <img src={requireDefault('textLogo')} alt="" style={{width: '55%'}}/>
                            <div className={'biggerFontSize'}>Nice to see you again</div>
                            <div className={'childrenSpacingTop'} style={{width: '3rem'}}>
                                <img src={data.upperCode} alt="" className={'width100'}/>
                                <div className={'padding flexCenter cursorPointer backgroundBlack ' +
                                    'borderRadius999 activeColor boxSizingBorderBox width100'}
                                     onClick={getUpperCode}>
                                    Refresh code
                                </div>
                            </div>
                            {
                                (() => {
                                    const login = () => requestPost('sys/syslogin', {
                                        ...temp,
                                        network: isDev ? 'arb_goerli' : 'arb_one'
                                    }).then(d => {
                                        const token = checkData([d, 'token'])
                                        if (token) {
                                            sessionStorage.setItem('token', d.token)
                                            location.reload()
                                        }
                                    })
                                    return [
                                        [
                                            ['Code', 'Enter upper code', 'code'],
                                            ['Login', 'Email or phone number', 'loginname'],
                                            ['Password', 'Enter password', 'pwd', 'hiddenPassword', undefined, login],
                                        ].map(v => inputContent(v)),
                                        <div onClick={login}
                                             className={'backgroundBlack borderRadius999 paddingBig activeColor flexCenter cursorPointer'}
                                        >
                                            Sign in
                                        </div>
                                    ]
                                })()
                            }
                        </div>
                        <div className={'flexCenter justifyContentSpaceBetween'}>
                            <div className={'flex alignItemsCenter childrenSpacingLeft'}>
                                <img src={requireDefault('account')} alt="" style={{width: '.7rem'}}
                                     className={'borderRadius999'}/>
                                <div className={'colorBlue'}>tech@petgpt.ai</div>
                            </div>
                            <div className={'colorGray'}>© PETGPT.AI</div>
                        </div>
                    </div>
                </div>
            </div>,
            dashboard: pageContent(<div className={'childrenSpacingTopBigger'}>
                <div className={'flexCenter justifyContentSpaceBetween'}>
                    <div className={'saf biggerFontSize'}>
                        DASHBOARD
                    </div>
                    <div className={'flexCenter childrenSpacingLeft'}>
                        <div className={'backgroundBlack flexCenter activeColor borderRadius999 cursorPointer'}
                             style={{padding: '.3rem .7rem .3rem .7rem'}}
                        >
                            + Buddy Punching
                        </div>
                        <div
                            className={'flexCenter borderRadius999 backgroundActiveColor cursorPointer'}
                            style={{
                                padding: '.24rem .64rem .24rem .64rem',
                                border: 'solid .06rem'
                            }}
                        >
                            Manager POV
                        </div>
                    </div>
                </div>
                <div className={'backgroundWhite paddingBigger flexCenter justifyContentSpaceBetween borderRadius'}>
                    <div className={'childrenSpacingTop'}>
                        <div className={'fontWeightBolder bigFontSize'}>Good to see you, Jari 👋</div>
                        <div className={'colorGray'}>You came 15 minutes early today.</div>
                    </div>
                    <div className={'childrenSpacingLeftBigger flexCenter'}>
                        {
                            [
                                ['#DCFF80', 'punchIn', '7:14 AM', 'Punch in'],
                                ['#FFB280', 'punchOut', '05:00 PM', 'Punch Out'],
                            ].map(v => <div className={'flexCenter childrenSpacingLeft'}>
                                <img className={'padding borderRadius'} src={requireDefault(v[1])} alt=""
                                     style={{height: '.8rem', background: v[0]}}/>
                                <div className={'childrenSpacingTop'}>
                                    <div className={'fontWeightBolder bigFontSize'}>{v[2]}</div>
                                    <div className={'colorGray'}>{v[3]}</div>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
                <div className={'backgroundWhite paddingBigger borderRadius flexCenter childrenFlexGrow1'}
                     style={{paddingLeft: 0, paddingRight: 0}}>
                    {
                        [
                            ['Total leave allowance', 34, 11, 41],
                            ['Total leave taken', 20, 62, 76],
                            ['Total leave available', 87, 50, 51],
                            ['Leave request pending', 122, 60, 53],
                        ].map((v, i) => <div
                            className={`childrenSpacingTopBig boxSizingBorderBox ${i ? 'borderLeftSmallGrayLine' : ''}`}
                            style={{borderColor: '#E0E0E0', padding: '0 1rem 0 1rem'}}
                        >
                            <div className={'colorGray'}>{v[0]}</div>
                            <div className={'biggerFontSize'}>{v[1]}</div>
                            <div className={'flex alignItemsCenter childrenSpacingLeftBig'}>
                                <div className={'flex alignItemsCenter childrenSpacingLeft'}>
                                    <div className={'colorGray'}>Paid</div>
                                    <div style={{color: '#0251FF'}}>{v[2]}</div>
                                </div>
                                <div className={'flex alignItemsCenter childrenSpacingLeft'}>
                                    <div className={'colorGray'}>Unpaid</div>
                                    <div style={{color: '#FF6600'}}>{v[3]}</div>
                                </div>
                            </div>
                        </div>)
                    }
                </div>
                <div className={'backgroundWhite paddingBigger borderRadius'}>
                    <div className={'flex'}>
                        <div className={'childrenSpacingTopBig flexGrow1'}>
                            <div className={'fontWeightBolder bigFontSize'}>Time Log</div>
                            <div className={'colorGray'}>Today</div>
                            <div className={'flex justifyContentSpaceBetween'}>
                                {
                                    [
                                        ['08:00', 'Scheduled'],
                                        ['12:00', 'Balance'],
                                        ['05:00', 'Worked'],
                                    ].map(v => <div className={'flexCenter justifyContentSpaceBetween'}>
                                        <div
                                            className={'paddingBig childrenSpacingTopBig backGroundBlueGray borderRadius'}
                                            style={{width: '1.5rem'}}
                                        >
                                            <div>{v[0]}</div>
                                            <div className={'colorGray wordBreakBreakAll'}>{v[1]}</div>
                                        </div>
                                    </div>)
                                }
                            </div>
                        </div>
                        <div className={'borderLeftSmallGrayLine'}
                             style={{borderColor: '#E0E0E0', margin: '0 1rem 0 1rem', height: '3rem'}}/>
                        <div className={'childrenSpacingTopBig flexGrow1'}>
                            <div className={'fontWeightBolder bigFontSize'}>This month</div>
                            <div className={'flexCenter flexWrap justifyContentSpaceBetween'}>
                                {
                                    [
                                        ['Total', 216],
                                        ['Worked time', 189],
                                        ['Shortage time', 23],
                                        ['Over time', 56],
                                    ].map(v => <div className={'childrenSpacingTop marginTopBig'}>
                                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                                <div className={'smallFontSize colorGray'}>{v[0]}</div>
                                                <div className={'smallFontSize'}>{v[1]} hour</div>
                                            </div>
                                            <div className={'borderRadius999 backGroundBlueGray'}
                                                 style={{height: '.2rem', width: '4.5rem'}}>
                                                <div className={'borderRadius height100'}
                                                     style={{width: '50%', background: '#146ADC'}}/>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (() => {
                        const arrProportion = arr => arr.map((v, i) => [
                            v,
                            [24, 24, 24, 24][i]
                        ])
                        return <div className={'backgroundWhite paddingBigger childrenSpacingTopBigger borderRadius'}>
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'fontWeightBolder bigFontSize'}>Announcements</div>
                            </div>
                            <div className={'childrenSpacingTopBigger'}>
                                {
                                    [
                                        <div
                                            className={'flexCenter justifyContentSpaceBetween padding backgroundGrey'}>
                                            {
                                                (() => {
                                                    const arr = arrProportion([
                                                        'Title',
                                                        'Start date',
                                                        'End date',
                                                        'Description',
                                                    ])
                                                    return arr.map(
                                                        v => <div style={{width: `${v[1]}%`}}
                                                                  className={`flex colorGray`}>{v[0]}</div>
                                                    )
                                                })()
                                            }
                                        </div>,
                                        ...[
                                            'Scrum Master',
                                            'Scrum Master',
                                            'Scrum Master',
                                            'Scrum Master',
                                            'Scrum Master',
                                            'Scrum Master',
                                        ].map(v => <div
                                                className={'flexCenter justifyContentSpaceBetween paddingLeftRight'}>
                                                {
                                                    (() => {
                                                        const arr = arrProportion([
                                                            v,
                                                            'Dec 4, 2019 21:42',
                                                            'Dec 7, 2019 23:26',
                                                            'Corrected item alignment',
                                                        ])
                                                        return arr.map(
                                                            v => <div
                                                                style={{width: `${v[1]}%`, height: '.74rem'}}
                                                                className={`flex alignItemsCenter textOverflowEllipsis`}>{v[0]}</div>
                                                        )
                                                    })()
                                                }
                                            </div>
                                        ),
                                        paging
                                    ]
                                }
                            </div>
                        </div>
                    })()
                }
            </div>),
            character: pageContent(
                (() => {
                    const arrProportion = arr => arr.map((v, i) => [
                            v,
                            [25, 25, 37, 5, 16][i]
                        ]),
                        {showEdit} = data,
                        setShowEdit = showEdit => {
                            !showEdit && setTemp()
                            setData({showEdit})
                        }
                    return <div>
                        <div className={'childrenSpacingTopBigger'}>
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'saf biggerFontSize'}>
                                    Chat Character
                                </div>
                                {button('+ Add Chat Character', () => setShowEdit(true))}
                            </div>
                            <div className={'backgroundWhite paddingBigger childrenSpacingTopBigger borderRadius'}>
                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div className={'fontWeightBolder bigFontSize'}>Character view</div>
                                </div>
                                <div className={'childrenSpacingTopBigger'}>
                                    {
                                        [
                                            <div
                                                className={'flexCenter justifyContentSpaceBetween padding backGroundBlueGray'}>
                                                {
                                                    (() => {
                                                        const arr = arrProportion(['TraitType', 'Attribute', 'Personality', 'Language', 'Active'])
                                                        return arr.map(
                                                            (v, i) => <div style={{width: `${v[1]}%`}}
                                                                           className={`flex colorGray  
                                                ${i === arr.length - 1 ? 'justifyContentFlexEnd' : ''}`}>{v[0]}</div>
                                                        )
                                                    })()
                                                }
                                            </div>,
                                            ...checkDataArray(data, 'chatFilter').map(v => <div
                                                    className={'flexCenter justifyContentSpaceBetween paddingLeftRight'}>
                                                    {
                                                        (() => {
                                                            const arr = arrProportion([
                                                                v.traitType,
                                                                v.attribute,
                                                                v.personality,
                                                                v.lang,
                                                                <div className={'flexCenter childrenSpacingLeftBigger'}>
                                                                    {
                                                                        [
                                                                            ['edit', () => {
                                                                                setTemp(v)
                                                                                setShowEdit(true)
                                                                            }],
                                                                            ['delete', () => {
                                                                                setTemp(v)
                                                                                setShowDeleteConfirm(true)
                                                                            }],
                                                                        ].map(v => <img src={requireDefault(v[0])}
                                                                                        alt=""
                                                                                        className={'cursorPointer'}
                                                                                        onClick={v[1]}
                                                                                        style={{width: '.5rem'}}/>)
                                                                    }
                                                                </div>,
                                                            ])
                                                            return arr.map(
                                                                (v, i) => <div
                                                                    style={{width: `${v[1]}%`, height: '.74rem'}}
                                                                    className={`flex alignItemsCenter textOverflowEllipsis 
                                                ${i === arr.length - 1 ? 'justifyContentFlexEnd' : ''}`}>{v[0]}</div>
                                                            )
                                                        })()
                                                    }
                                                </div>
                                            ),
                                            paging
                                        ]
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            showEdit &&
                            <div className={'positionFixed flexColumnCenter'}
                                 style={{
                                     top: 0,
                                     left: 0,
                                     right: 0,
                                     bottom: 0,
                                     zIndex: 1000,
                                     background: 'rgba(0,0,0,.56)',
                                 }}>
                                <div
                                    className={'backgroundWhite paddingBigger borderRadius childrenSpacingTopBigger overflowYScroll'}
                                    style={{width: '45%', maxHeight: '100vh'}}
                                >
                                    <div className={'flexCenter justifyContentSpaceBetween'}>
                                        <div style={{fontSize: '.6rem'}}>Add Chat Character</div>
                                        <img onClick={() => setShowEdit()} src={requireDefault('close')} alt=""
                                             style={{width: '.5rem'}} className={'cursorPointer'}/>
                                    </div>
                                    <div className={'childrenSpacingTopBigger'}>
                                        {
                                            [
                                                ['TraitType', 'Mouth', 'traitType'],
                                                ['Attribute', 'Teeth', 'attribute'],
                                                ['Personality', '有点憨憨/傻傻可爱', 'personality'],
                                                ['Language', 'zh', 'lang'],
                                            ].map(v => inputContent(v))
                                        }
                                        <div
                                            className={'flex alignItemsCenter childrenSpacingLeftBig'}>
                                            <div
                                                className={'backgroundBlack flexCenter activeColor borderRadius999 cursorPointer'}
                                                style={{padding: '.3rem .7rem .3rem .7rem'}}
                                                onClick={() => requestPost(
                                                    'back/insertOrUpdateCharacter',
                                                    temp
                                                ).then(r => handleToast(r, () => {
                                                    getCharacters()
                                                    setShowEdit()
                                                }))}
                                            >
                                                Save
                                            </div>
                                            <div
                                                className={'flexCenter borderRadius999 cursorPointer'}
                                                style={{
                                                    padding: '.24rem .64rem .24rem .64rem',
                                                    border: 'solid .06rem'
                                                }}
                                                onClick={() => setShowEdit()}
                                            >
                                                Cancel
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {deleteConfirm('deleteCharacter', getCharacters)}
                    </div>
                })()
            ),
            cattype: pageContent(
                (() => {
                    const arrProportion = arr => arr.map((v, i) => [
                            v,
                            [10, 45, 5, 15, 10][i]
                        ]),
                        {showEdit} = data,
                        setShowEdit = showEdit => {
                            !showEdit && setTemp()
                            setData({showEdit})
                        }
                    return <div>
                        <div className={'childrenSpacingTopBigger'}>
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'saf biggerFontSize'}>
                                    Chat Character
                                </div>
                                {button(
                                    '+ Add Chat CatType',
                                    () => {
                                        setTemp({
                                            typeName: 'common',
                                            model: 'gpt-3.5-turbo-instruct'
                                        })
                                        setShowEdit(true)
                                    }
                                )}
                            </div>
                            <div className={'backgroundWhite paddingBigger childrenSpacingTopBig borderRadius'}>
                                <div className={'fontWeightBolder bigFontSize'}>猫咪 CatType 可以添加的变量</div>
                                <div className={'flex alignItemsCenter flexWrap'}>
                                    {
                                        [
                                            '${Eyes} 眼睛',
                                            '${Ears} 耳朵',
                                            '${Tail} 尾巴',
                                            '${Fur} 皮毛',
                                            '${Display} 显示',
                                            '${Mouth} 嘴巴',
                                            '${Nose} 鼻子',
                                            '${Whiskers} 胡须',
                                        ].map(v => <div
                                            className={'paddingSmall backGroundBlueGray borderRadius999 marginRight marginTop'}>
                                            {v}
                                        </div>)
                                    }
                                </div>
                            </div>
                            <div className={'backgroundWhite paddingBigger childrenSpacingTopBigger borderRadius'}>
                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div className={'fontWeightBolder bigFontSize'}>CatType view</div>
                                </div>
                                <div className={'childrenSpacingTopBigger'}>
                                    {
                                        [
                                            <div
                                                className={'flexCenter justifyContentSpaceBetween padding backGroundBlueGray'}>
                                                {
                                                    (() => {
                                                        const arr = arrProportion(['TypeName', 'CharacterMessage', 'Language', 'Model', 'Active'])
                                                        return arr.map(
                                                            (v, i) => <div style={{width: `${v[1]}%`}}
                                                                           className={`flex colorGray  
                                                ${i === arr.length - 1 ? 'justifyContentFlexEnd' : ''}`}>{v[0]}</div>
                                                        )
                                                    })()
                                                }
                                            </div>,
                                            ...checkDataArray(data, 'chatFilter').map(v => <div
                                                    className={'flexCenter justifyContentSpaceBetween paddingLeftRight'}>
                                                    {
                                                        (() => {
                                                            const arr = arrProportion([
                                                                v.typeName,
                                                                v.characterMessage,
                                                                v.lang,
                                                                v.model,
                                                                <div className={'flexCenter childrenSpacingLeftBigger'}>
                                                                    {
                                                                        [
                                                                            ['edit', () => {
                                                                                setTemp(v)
                                                                                setShowEdit(true)
                                                                            }],
                                                                            ['delete', () => {
                                                                                setTemp(v)
                                                                                setShowDeleteConfirm(true)
                                                                            }],
                                                                        ].map(v => <img src={requireDefault(v[0])}
                                                                                        alt=""
                                                                                        className={'cursorPointer'}
                                                                                        onClick={v[1]}
                                                                                        style={{width: '.5rem'}}/>)
                                                                    }
                                                                </div>,
                                                            ])
                                                            return arr.map(
                                                                (v, i) => <div
                                                                    style={{width: `${v[1]}%`, height: '.74rem'}}
                                                                    className={`flex alignItemsCenter textOverflowEllipsis 
                                                ${i === arr.length - 1 ? 'justifyContentFlexEnd' : ''}`}>{v[0]}</div>
                                                            )
                                                        })()
                                                    }
                                                </div>
                                            ),
                                            paging
                                        ]
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            showEdit &&
                            <div className={'positionFixed flexColumnCenter'}
                                 style={{
                                     top: 0,
                                     left: 0,
                                     right: 0,
                                     bottom: 0,
                                     zIndex: 1000,
                                     background: 'rgba(0,0,0,.56)',
                                 }}>
                                <div
                                    className={'backgroundWhite paddingBigger borderRadius childrenSpacingTopBigger overflowYScroll'}
                                    style={{width: '45%', maxHeight: '100vh'}}
                                >
                                    <div className={'flexCenter justifyContentSpaceBetween'}>
                                        <div style={{fontSize: '.6rem'}}>Add Chat CatType</div>
                                        <img onClick={() => setShowEdit()} src={requireDefault('close')} alt=""
                                             style={{width: '.5rem'}} className={'cursorPointer'}/>
                                    </div>
                                    <div className={'childrenSpacingTopBigger'}>
                                        {
                                            [
                                                ['TypeName', 'common', 'typeName'],
                                                ['CharacterMessage', '我们来玩一个角色扮演游戏，在扮演游戏里，你扮演一只猫，你比较${Mouth}，喜欢吃鱼，用第一人称回答我，以简短内容回复，回复内容不能超过100个字', 'characterMessage', undefined, true],
                                                ['Language', 'zh', 'lang'],
                                                ['Model', 'gpt-3.5-turbo-instruct', 'model'],
                                            ].map(v => inputContent(v))
                                        }
                                        <div
                                            className={'flex alignItemsCenter childrenSpacingLeftBig'}>
                                            <div
                                                className={'backgroundBlack flexCenter activeColor borderRadius999 cursorPointer'}
                                                style={{padding: '.3rem .7rem .3rem .7rem'}}
                                                onClick={() => requestPost(
                                                    'back/insertOrUpdateCatType',
                                                    temp
                                                ).then(r => handleToast(r, () => {
                                                    getCatTypes()
                                                    setShowEdit()
                                                }))}
                                            >
                                                Save
                                            </div>
                                            <div
                                                className={'flexCenter borderRadius999 cursorPointer'}
                                                style={{
                                                    padding: '.24rem .64rem .24rem .64rem',
                                                    border: 'solid .06rem'
                                                }}
                                                onClick={() => setShowEdit()}
                                            >
                                                Cancel
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {deleteConfirm('deleteCatType', getCatTypes)}
                    </div>
                })()
            ),
            boostcard: pageContent(
                (() => {
                    const arrProportion = arr => arr.map((v, i) => [
                            v,
                            [5, 5, 10, 15, 10, 15, 15][i]
                        ]),
                        {showEdit} = data,
                        setShowEdit = showEdit => {
                            !showEdit && setTemp()
                            setData({showEdit})
                        },
                        {multiNum} = temp,
                        multiNums = {5: 30, 10: 16, 20: 9},
                        multiNumsKeys = Object.keys(multiNums)
                    return <div>
                        <div className={'childrenSpacingTopBigger'}>
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'saf biggerFontSize'}>
                                    Boost Card
                                </div>
                                {button(
                                    '+ Add Boost Card',
                                    () => {
                                        setTemp({multiNum: multiNumsKeys[0]})
                                        setShowEdit(true)
                                    }
                                )}
                            </div>
                            <div className={'backgroundWhite paddingBigger childrenSpacingTopBig borderRadius'}>
                                <div className={'fontWeightBolder bigFontSize'}>Each cat gets one chance to use the
                                    BOOST card
                                </div>
                            </div>
                            <div className={'backgroundWhite paddingBigger childrenSpacingTopBigger borderRadius'}>
                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div className={'fontWeightBolder bigFontSize'}>Boost Card view</div>
                                    <div
                                        className={'padding backGroundBlueGray flex alignItemsCenter childrenSpacingLeft borderRadius999'}>
                                        <img src={requireDefault('search')} alt="" style={{width: '.4rem'}}/>
                                        <input type='text' placeholder='User ID'
                                               style={{width: '3rem'}}
                                               onChange={e => setData({userId: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className={'childrenSpacingTopBigger'}>
                                    {
                                        [
                                            <div
                                                className={'flexCenter justifyContentSpaceBetween padding backGroundBlueGray'}>
                                                {
                                                    (() => {
                                                        const arr = arrProportion(['User ID', 'Cat ID', 'Multi Num', 'Valid Time/S', 'Invitee User Id', 'Start Time', 'Create Time'])
                                                        return arr.map(
                                                            (v, i) => <div style={{width: `${v[1]}%`}}
                                                                           className={`flex colorGray  
                                                ${i === arr.length - 1 ? 'justifyContentFlexEnd' : ''}`}>{v[0]}</div>
                                                        )
                                                    })()
                                                }
                                            </div>,
                                            ...checkDataArray(data, 'chatFilter').map(v => <div
                                                    className={'flexCenter justifyContentSpaceBetween paddingLeftRight'}>
                                                    {
                                                        (() => {
                                                            const arr = arrProportion([
                                                                v.userId,
                                                                v.id,
                                                                v.multiNum,
                                                                v.validTime,
                                                                v.inviteeUserId,
                                                                ...['startTime', 'createTime'].map(
                                                                    v1 => {
                                                                        const time = v[v1]
                                                                        return time
                                                                            ? new Date(time)
                                                                                .toLocaleString('cn', {hour12: false})
                                                                            : ''
                                                                    }
                                                                )
                                                            ])
                                                            return arr.map(
                                                                (v, i) => <div
                                                                    style={{width: `${v[1]}%`, height: '.74rem'}}
                                                                    className={`flex alignItemsCenter textOverflowEllipsis 
                                                ${i === arr.length - 1 ? 'justifyContentFlexEnd' : ''}`}>{v[0]}</div>
                                                            )
                                                        })()
                                                    }
                                                </div>
                                            ),
                                            paging
                                        ]
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            showEdit &&
                            <div className={'positionFixed flexColumnCenter'}
                                 style={{
                                     top: 0,
                                     left: 0,
                                     right: 0,
                                     bottom: 0,
                                     zIndex: 1000,
                                     background: 'rgba(0,0,0,.56)',
                                 }}>
                                <div
                                    className={'backgroundWhite paddingBigger borderRadius childrenSpacingTopBigger overflowYScroll'}
                                    style={{width: '45%', maxHeight: '100vh'}}
                                >
                                    <div className={'flexCenter justifyContentSpaceBetween'}>
                                        <div style={{fontSize: '.6rem'}}>Add Boost Card</div>
                                        <img onClick={() => setShowEdit()} src={requireDefault('close')} alt=""
                                             style={{width: '.5rem'}} className={'cursorPointer'}/>
                                    </div>
                                    <div className={'childrenSpacingTopBigger'}>
                                        {inputContent(['Cat ID', '', 'catId'])}
                                        {[
                                            <div className={'childrenSpacingTopBig'}>
                                                <div>Multi Num</div>
                                                <Radio.Group value={multiNum}
                                                             onChange={e => setTemp({multiNum: e.target.value})}
                                                >
                                                    {multiNumsKeys.map(v => <Radio value={v}>{v}</Radio>)}
                                                </Radio.Group>
                                            </div>,
                                            inputContent([
                                                'Valid Time/S',
                                                npTimes(npTimes(npTimes(checkData0(multiNums, multiNum), 24), 60), 60),
                                                undefined, undefined, undefined, undefined, true])
                                        ]}
                                        <div
                                            className={'flex alignItemsCenter childrenSpacingLeftBig'}>
                                            <div
                                                className={'backgroundBlack flexCenter activeColor borderRadius999 cursorPointer'}
                                                style={{padding: '.3rem .7rem .3rem .7rem'}}
                                                onClick={() => requestPost(
                                                    'back/insertAccelerator',
                                                    temp
                                                ).then(r => handleToast(r, () => {
                                                    getBoostCard()
                                                    setShowEdit()
                                                }))}
                                            >
                                                Save
                                            </div>
                                            <div
                                                className={'flexCenter borderRadius999 cursorPointer'}
                                                style={{
                                                    padding: '.24rem .64rem .24rem .64rem',
                                                    border: 'solid .06rem'
                                                }}
                                                onClick={() => setShowEdit()}
                                            >
                                                Cancel
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {deleteConfirm('deleteCatType', getCatTypes)}
                    </div>
                })()
            ),
            dropnft: pageContent(
                (() => {
                    const arrProportion = arr => arr.map((v, i) => [
                            v,
                            [17, 17, 17, 17, 17][i]
                        ]),
                        {showEdit} = data,
                        setShowEdit = showEdit => {
                            !showEdit && setTemp()
                            setData({showEdit})
                        },
                        {nftInfo} = data,
                        nftInfos = {
                            'Piggy Bank RARE': {
                                nftType: 7,
                                nftTempId: 12,
                            },
                            'Piggy Bank LEGENDARY': {
                                nftType: 7,
                                nftTempId: 13,
                            },
                            'White Tank Top': {
                                nftType: 4,
                                nftTempId: 4010,
                            },
                            'Neon Yellow Cyberpunk Legendary': {
                                nftType: 4,
                                nftTempId: 4102,
                            },
                            'Litter Box LEGENDARY': {
                                nftType: 1,
                                nftTempId: 2,
                            },
                            'Straw Bowler Rare': {
                                nftType: 6,
                                nftTempId: 6009,
                            },
                            'Litter Box RARE': {
                                nftType: 1,
                                nftTempId: 3,
                            },
                        },
                        nftInfosKeys = Object.keys(nftInfos)
                    return <div>
                        <div className={'childrenSpacingTopBigger'}>
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'saf biggerFontSize'}>
                                    Drop NFT
                                </div>
                                {button(
                                    '+ Drop NFT',
                                    () => {
                                        setData({nftInfo: nftInfosKeys[0]})
                                        setShowEdit(true)
                                    }
                                )}
                            </div>
                            <div className={'backgroundWhite paddingBigger childrenSpacingTopBig borderRadius'}>
                                <div className={'fontWeightBolder bigFontSize'}>
                                    Each user, one type of nft can only be drop once
                                </div>
                            </div>
                            <div className={'backgroundWhite paddingBigger childrenSpacingTopBigger borderRadius'}>
                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div className={'fontWeightBolder bigFontSize'}>Drop NFT view</div>
                                    <div
                                        className={'padding backGroundBlueGray flex alignItemsCenter childrenSpacingLeft borderRadius999'}>
                                        <img src={requireDefault('search')} alt="" style={{width: '.4rem'}}/>
                                        <input type='text' placeholder='User ID'
                                               style={{width: '3rem'}}
                                               onChange={e => setData({userId: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className={'childrenSpacingTopBigger'}>
                                    {
                                        [
                                            <div
                                                className={'flexCenter justifyContentSpaceBetween padding backGroundBlueGray'}>
                                                {
                                                    (() => {
                                                        const arr = arrProportion(['Token ID', 'User ID', 'NFT Item Id', 'Type Id', 'Create Time'])
                                                        return arr.map(
                                                            (v, i) => <div style={{width: `${v[1]}%`}}
                                                                           className={`flex colorGray  
                                                ${i === arr.length - 1 ? 'justifyContentFlexEnd' : ''}`}>{v[0]}</div>
                                                        )
                                                    })()
                                                }
                                            </div>,
                                            ...checkDataArray(data, 'chatFilter').map(v => <div
                                                    className={'flexCenter justifyContentSpaceBetween paddingLeftRight'}>
                                                    {
                                                        (() => {
                                                            const arr = arrProportion([
                                                                v.id,
                                                                v.userId,
                                                                v.nftItemId,
                                                                v.typeId,
                                                                ...['createTime'].map(
                                                                    v1 => {
                                                                        const time = v[v1]
                                                                        return time
                                                                            ? new Date(time)
                                                                                .toLocaleString('cn', {hour12: false})
                                                                            : ''
                                                                    }
                                                                )
                                                            ])
                                                            return arr.map(
                                                                (v, i) => <div
                                                                    style={{width: `${v[1]}%`, height: '.74rem'}}
                                                                    className={`flex alignItemsCenter textOverflowEllipsis 
                                                ${i === arr.length - 1 ? 'justifyContentFlexEnd' : ''}`}>{v[0]}</div>
                                                            )
                                                        })()
                                                    }
                                                </div>
                                            ),
                                            paging
                                        ]
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            showEdit &&
                            <div className={'positionFixed flexColumnCenter'}
                                 style={{
                                     top: 0,
                                     left: 0,
                                     right: 0,
                                     bottom: 0,
                                     zIndex: 1000,
                                     background: 'rgba(0,0,0,.56)',
                                 }}>
                                <div
                                    className={'backgroundWhite paddingBigger borderRadius childrenSpacingTopBigger overflowYScroll'}
                                    style={{width: '45%', maxHeight: '100vh'}}
                                >
                                    <div className={'flexCenter justifyContentSpaceBetween'}>
                                        <div style={{fontSize: '.6rem'}}>Drop NFT</div>
                                        <img onClick={() => setShowEdit()} src={requireDefault('close')} alt=""
                                             style={{width: '.5rem'}} className={'cursorPointer'}/>
                                    </div>
                                    <div className={'childrenSpacingTopBigger'}>
                                        {inputContent(['User ID', '', 'userId'])}
                                        <div className={'childrenSpacingTopBig'}>
                                            <div>NFT</div>
                                            <Radio.Group value={nftInfo}
                                                         onChange={e => setData({nftInfo: e.target.value})}
                                            >
                                                {nftInfosKeys.map(v => <Radio value={v}>{v}</Radio>)}
                                            </Radio.Group>
                                        </div>
                                        <div
                                            className={'flex alignItemsCenter childrenSpacingLeftBig'}>
                                            <div
                                                className={'backgroundBlack flexCenter activeColor borderRadius999 cursorPointer'}
                                                style={{padding: '.3rem .7rem .3rem .7rem'}}
                                                onClick={() => requestPost(
                                                    'back/insertOrUpdateNft',
                                                    {
                                                        ...temp,
                                                        ...checkDataObject(nftInfos, checkData([data, 'nftInfo']))
                                                    }
                                                ).then(r => handleToast(r, () => {
                                                    getDropNftHistory()
                                                    setShowEdit()
                                                }))}
                                            >
                                                Save
                                            </div>
                                            <div
                                                className={'flexCenter borderRadius999 cursorPointer'}
                                                style={{
                                                    padding: '.24rem .64rem .24rem .64rem',
                                                    border: 'solid .06rem'
                                                }}
                                                onClick={() => setShowEdit()}
                                            >
                                                Cancel
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {deleteConfirm('deleteCatType', getCatTypes)}
                    </div>
                })()
            ),
            nftmanage: pageContent((() => {
                const setShowEdit = (editKey, showEdit) => {
                        !showEdit && setTemp()
                        setData({[editKey]: showEdit})
                    },
                    setShowSyncNFT = showEdit => setShowEdit('syncNFT', showEdit)
                return <div>
                    <div className={'childrenSpacingTopBigger'}>
                        <div className={'flex flexWrap alignItemsCenter'}>
                            <div className={'paddingBottomBig paddingRightBig'}>
                                {button('Sync NFT', () => setShowSyncNFT(true))}
                            </div>
                        </div>
                    </div>
                    {
                        data.syncNFT &&
                        <div className={'positionFixed flexColumnCenter'}
                             style={{
                                 top: 0,
                                 left: 0,
                                 right: 0,
                                 bottom: 0,
                                 zIndex: 1000,
                                 background: 'rgba(0,0,0,.56)',
                             }}>
                            <div
                                className={'backgroundWhite paddingBigger borderRadius childrenSpacingTopBigger overflowYScroll'}
                                style={{width: '45%', maxHeight: '100vh'}}
                            >
                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div style={{fontSize: '.6rem'}}>Sync NFT</div>
                                    <img onClick={() => setShowSyncNFT()} src={requireDefault('close')} alt=""
                                         style={{width: '.5rem'}} className={'cursorPointer'}/>
                                </div>
                                <div className={'childrenSpacingTopBigger'}>
                                    {inputContent(['Token Id Str', '1,2,3', 'tokenIdStr'])}
                                    <div
                                        className={'flex alignItemsCenter childrenSpacingLeftBig'}>
                                        <div
                                            className={'backgroundBlack flexCenter activeColor borderRadius999 cursorPointer'}
                                            style={{padding: '.3rem .7rem .3rem .7rem'}}
                                            onClick={() => requestPost(
                                                'back/syncNftMetadata',
                                                temp
                                            ).then(r => {
                                                if (isNotNull(r)) {
                                                    toast(JSON.stringify(r))
                                                    setShowSyncNFT()
                                                }
                                            })}
                                        >
                                            Save
                                        </div>
                                        <div
                                            className={'flexCenter borderRadius999 cursorPointer'}
                                            style={{
                                                padding: '.24rem .64rem .24rem .64rem',
                                                border: 'solid .06rem'
                                            }}
                                            onClick={() => setShowSyncNFT()}
                                        >
                                            Cancel
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            })()),
            setdrop: pageContent(
                (() => {
                    const arrProportion = arr => arr.map((v, i) => [
                            v,
                            [10, 10, 30, 35, 10][i]
                        ]),
                        {showEdit} = data,
                        setShowEdit = showEdit => {
                            !showEdit && setTemp()
                            setData({showEdit})
                        }
                    return <div>
                        <div className={'childrenSpacingTopBigger'}>
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'saf biggerFontSize'}>
                                    Set Drop
                                </div>
                                {button(
                                    'Set Drop',
                                    () => setShowEdit(true)
                                )}
                            </div>
                            <div className={'backgroundWhite paddingBigger childrenSpacingTopBigger borderRadius'}>
                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div className={'fontWeightBolder bigFontSize'}>NFT Item view</div>
                                    <div
                                        className={'padding backGroundBlueGray flex alignItemsCenter childrenSpacingLeft borderRadius999'}>
                                        <img src={requireDefault('search')} alt="" style={{width: '.4rem'}}/>
                                        <input type='text' placeholder='Type ID'
                                               style={{width: '3rem'}}
                                               onChange={e => setData({userId: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className={'childrenSpacingTopBigger'}>
                                    {
                                        [
                                            <div
                                                className={'flexCenter justifyContentSpaceBetween padding backGroundBlueGray'}>
                                                {
                                                    (() => {
                                                        const arr = arrProportion(['NFT Item Id', 'Type Id', 'Name', 'Drop Item Ids', 'Action'])
                                                        return arr.map(
                                                            (v, i) => <div style={{width: `${v[1]}%`}}
                                                                           className={`flex colorGray 
                                                                      ${i === arr.length - 1 ? 'justifyContentFlexEnd' : ''}`}>{v[0]}</div>
                                                        )
                                                    })()
                                                }
                                            </div>,
                                            ...checkDataArray(data, 'chatFilter').map(v => <div
                                                    className={'flexCenter justifyContentSpaceBetween paddingLeftRight'}>
                                                    {
                                                        (() => {
                                                            const arr = arrProportion([
                                                                v.id,
                                                                v.itemTypeId,
                                                                v.name,
                                                                v.dropItemIds,
                                                                <div className={'flexCenter childrenSpacingLeftBigger'}>
                                                                    {
                                                                        [
                                                                            ['edit', () => {
                                                                                setTemp({
                                                                                    nftItemId: v.id,
                                                                                    nftName: v.name
                                                                                })
                                                                                setShowEdit(true)
                                                                            }]
                                                                        ].map(v => <img src={requireDefault(v[0])}
                                                                                        alt=""
                                                                                        className={'cursorPointer'}
                                                                                        onClick={v[1]}
                                                                                        style={{width: '.5rem'}}/>)
                                                                    }
                                                                </div>
                                                            ])
                                                            return arr.map(
                                                                (v, i) => <div
                                                                    style={{width: `${v[1]}%`, height: '.74rem'}}
                                                                    className={`flex alignItemsCenter textOverflowEllipsis 
                                                                    ${i === arr.length - 1 ? 'justifyContentFlexEnd' : ''}`}>{v[0]}</div>
                                                            )
                                                        })()
                                                    }
                                                </div>
                                            ),
                                            paging
                                        ]
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            showEdit &&
                            <div className={'positionFixed flexColumnCenter'}
                                 style={{
                                     top: 0,
                                     left: 0,
                                     right: 0,
                                     bottom: 0,
                                     zIndex: 1000,
                                     background: 'rgba(0,0,0,.56)',
                                 }}>
                                <div
                                    className={'backgroundWhite paddingBigger borderRadius childrenSpacingTopBigger overflowYScroll'}
                                    style={{width: '45%', maxHeight: '100vh'}}
                                >
                                    <div className={'flexCenter justifyContentSpaceBetween'}>
                                        <div style={{fontSize: '.6rem'}}>Set Drop</div>
                                        <img onClick={() => setShowEdit()} src={requireDefault('close')} alt=""
                                             style={{width: '.5rem'}} className={'cursorPointer'}/>
                                    </div>
                                    <div className={'childrenSpacingTopBigger'}>
                                        {
                                            [
                                                ['NFT Item Id', '', 'nftItemId'],
                                                ['NFT Name', '', 'nftName'],
                                                ['Rules', 'Pink JK Skirt Legendary:50,Pink Tutu Legendary:50', 'rules'],
                                            ].map(v => inputContent(v))
                                        }
                                        <div
                                            className={'flex alignItemsCenter childrenSpacingLeftBig'}>
                                            <div
                                                className={'backgroundBlack flexCenter activeColor borderRadius999 cursorPointer'}
                                                style={{padding: '.3rem .7rem .3rem .7rem'}}
                                                onClick={() => {
                                                    const {nftItemId} = temp
                                                    requestPost(
                                                        'back/setDropIds',
                                                        {
                                                            ...temp,
                                                            nftItemId: isNotNullOrEmpty(nftItemId) ? nftItemId : 0
                                                        }
                                                    ).then(r => handleToast(r, () => {
                                                        getGameNftItem()
                                                        setShowEdit()
                                                    }))
                                                }}
                                            >
                                                Save
                                            </div>
                                            <div
                                                className={'flexCenter borderRadius999 cursorPointer'}
                                                style={{
                                                    padding: '.24rem .64rem .24rem .64rem',
                                                    border: 'solid .06rem'
                                                }}
                                                onClick={() => setShowEdit()}
                                            >
                                                Cancel
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {deleteConfirm('deleteCatType', getCatTypes)}
                    </div>
                })()
            )
        },
        pathNames = Object.keys(paths),
        [firstPath] = pathNames,
        path = location.pathname.slice(1).split('/')[0],
        userIdGet = () => {
            path === 'boostcard' && getBoostCard()
            path === 'dropnft' && getDropNftHistory()
            path === 'setdrop' && getGameNftItem()
        }
    [
        [
            () => {
                setData({init: true})
                const isLogin = pN0 === 'login'
                isLogin && getUpperCode()
                token ? (isLogin && windowReplace(firstPath)) : (!isLogin && windowReplace('login'))
            },
        ],
        [
            () => {
                path === 'filter' && getChatFilter()
                path === 'character' && getCharacters()
                path === 'cattype' && getCatTypes()
                userIdGet()
            },
            [current, size]
        ],
        [
            () => {
                isNotNull(userId) && (current !== 1 ? setCurrent(1) : userIdGet())
            },
            [userId]
        ]
    ].map(v => useEffect(v[0], checkDataArray(v, 1)))
    return <>
        {contextHolder}
        <Routes>
            <Route path='*' element={<Navigate to={firstPath}/>}/>
            {pathNames.map(v => <Route key={v} path={v} element={paths[v]}/>)}
        </Routes>
        {loading}
    </>
})